<section class="content" *ngIf="show">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="card">
                    <div class="body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="ngxTableHeader pl-0 pb-0">
                                    <ul class="header-buttons-left ml-0">
                                        <li class="dropdown m-l-20">
                                            <label for="search-input"><i class="material-icons search-icon">search</i></label>
                                            <input placeholder="Recherche" type="text" class="browser-default search-field search-team" (keyup)='entiteChange(idCurrentEse, $event.target.value)' aria-label="Search box">
                                        </li>
                                        <li class="dropdown m-l-20" *ngIf="securityService.admin">
                                            <select [(ngModel)]="idCurrentEse" class="form-control select-trie pl-0 pr-0" (change)="entiteChange($event.target.value, filter)" title="Entité">
                                                <option [value]="null" >Toutes les entités</option>
                                                <option *ngFor="let e of entreprises" [value]="e.id">{{e.denomination}}</option>
                                            </select>
                                        </li>
                                    </ul>
                                    <ul class="header-buttons m-r-20" *ngIf="entreprises && entreprises.length>0 && securityService.admin && (+idCurrentEse)">
                                        <li>
                                            <div class="icon-button-demo">
                                                <button mat-mini-fab color="primary" (click)="openFileUload.click()" title="Importer la liste des utilisateurs de cette entreprise">
                                                    <mat-icon class="col-white">file_upload</mat-icon>
                                                </button>
                                            </div>
                                            <input type="file" hidden #openFileUload (change)="uploadPersonnels($event)">
                                        </li>
                                        <li>
                                            <div class="icon-button-demo">
                                                <button mat-mini-fab class="bg-red" (click)="removeAllUser()" title="Supprimer la liste des utilisateurs de cette entreprise">
                                                    <mat-icon class="col-white">delete</mat-icon>
                                                </button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <ngx-datatable #table class="material" [rows]="personnels" [columns]="" [sortType]="'multi'" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'60'" [limit]="10">
                                    <!-- user image -->
                                    <ngx-datatable-column name="" sortable="false" prop="image" [width]="10">
                                        <ng-template let-row="data" let-value="value" let-i="index" ngx-datatable-cell-template>
                                            <td class="table-img padding-0">
                                                <img width="40" src="{{ apiImageUrl + value }}" />
                                            </td>
                                        </ng-template>
                                    </ngx-datatable-column>
                                  
                                    <ngx-datatable-column name="Prénom et nom" sortable="false" prop="nom" [width]="130">
                                        <ng-template let-row="data" let-value="value" let-i="index" ngx-datatable-cell-template>
                                            <td class="table-img padding-0" [title]="value">
                                                {{value}}
                                            </td>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Département" sortable="false" prop="departement" [width]="130">
                                        <ng-template let-row="data" let-value="value" let-i="index" ngx-datatable-cell-template>
                                            <td class="table-img padding-0" [title]="value">
                                                {{value}}
                                            </td>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Poste" sortable="false" prop="poste" [width]="130">
                                        <ng-template let-row="data" let-value="value" let-i="index" ngx-datatable-cell-template>
                                            <td class="table-img padding-0" [title]="value">
                                                {{value}}
                                            </td>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Entité" sortable="false" prop="entreprises" [width]="130">
                                        <ng-template let-row="data" let-value="value" let-i="index" ngx-datatable-cell-template>
                                            <td class="table-img padding-0">
                                                <span *ngIf="value && value.length==1  && securityService.admin" [title]="value[0].denomination">{{value[0].denomination}}</span>
                                                <select *ngIf="value && value.length>1 && securityService.admin" class="form-control no-border pl-0" style="border: none !important;">
                                                   <option *ngFor="let e of value">{{e.denomination}}</option> 
                                                </select>
                                            </td>
                                        </ng-template>
                                    </ngx-datatable-column>

                                    <ngx-datatable-column name="Actions" sortable="false" [width]="10" class="text-center" *ngIf="securityService.admin">
                                        <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex"
                                            ngx-datatable-cell-template>
                                            <span>
                                                <i class="material-icons text-danger pointer" title="Supprimer cet utilisateur" (click)="removeUser(row)">delete</i>
                                            </span>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    
                                    <ngx-datatable-footer>
                                        <ng-template 
                                          ngx-datatable-footer-template
                                          let-rowCount="rowCount"
                                          let-pageSize="pageSize"
                                          let-selectedCount="selectedCount"
                                          let-curPage="curPage"
                                          let-offset="offset"
                                          let-isVisible="isVisible">
                                            <div class="page-count">
                                              <span *ngIf="selectedMessage">
                                                {{selectedCount.toLocaleString()}} {{selectedMessage}} / 
                                              </span>
                                               {{totalMessage}} {{rowCount.toLocaleString()}}
                                            </div>
                                            <datatable-pager
                                                [pagerLeftArrowIcon]="'datatable-icon-left'"
                                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                                [pagerPreviousIcon]="'datatable-icon-prev'"
                                                [pagerNextIcon]="'datatable-icon-skip'"
                                                [page]="curPage"
                                                [size]="pageSize"
                                                [count]="rowCount"
                                                [hidden]="!((rowCount / pageSize) > 1)"
                                                (change)="table.onFooterPage($event)"
                                                (change)="look($event)"
                                            >
                                            </datatable-pager>
                                        </ng-template>
                                    </ngx-datatable-footer>
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>