import { Component, OnInit } from '@angular/core';
import {
  Event,
  Router,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';
import { PlatformLocation } from '@angular/common';

import { InventaireService } from 'src/app/data/services/inventaire/inventaire.service';
import { SecurityService } from 'src/app/shared/service/security.service';
import { AuthenticationService } from 'src/app/data/services/authentication/authentication.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.sass']
})
export class ContentLayoutComponent implements OnInit {

  currentUrl: string;
  detruit:boolean=false;
  idCurrentInv: any;
  inventaires: any[];
  isAuthenticated = false;

  constructor(
    public _router: Router,
    location: PlatformLocation,
    public securityService: SecurityService,
    public router:Router,
    private authenticationService: AuthenticationService,
    private inventaireService: InventaireService
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.securityService.guestAccess(routerEvent.url)
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    this.inventaires = [];
    if (this.authenticationService.isAuthenticated()) {
      this.securityService.currentEse.subscribe((res: number) => {
        this.idCurrentInv = localStorage.getItem('currentInv');
        this.getInventaires();
      });
      this.isAuthenticated = true;
    }
    if(localStorage.getItem('token')){
      this.securityService.load();
    }
    this.shortWidth();
  }

  getInventaires() {
    if (localStorage.getItem('currentEse')) {
      this.inventaireService.getInventaireByEse(localStorage.getItem('currentEse')).then((res) => {
        this.inventaires = res;
        let currentInv: any;
        if (!this.inventaires || this.inventaires.length <= 0) {
          this.inventaires = [];
          return;
        }

        if (this.inventaires.length == 1) {
          currentInv = this.inventaires[0];
        }
        
        if (this.inventaires.length > 1) {
          currentInv = this.inventaires.find((inv: any) => {
            return this.idCurrentInv ? inv.id === (+this.idCurrentInv) : inv.status == 'open';
          });
        }

        if (currentInv) {
          localStorage.setItem('currentInv', currentInv?.id);
          this.idCurrentInv = localStorage.getItem('currentInv');
          if (currentInv.status == 'close') {
            localStorage.setItem('inventaireIsClosed', '1');
          } else {
            localStorage.removeItem('inventaireIsClosed');
          }
        }
      }) 
    }
  }

  inventaireChange(value) {
    localStorage.setItem('currentInv', value);
    window.location.reload();
  }

  shortWidth() {
    if(window.innerWidth<600){
      this.securityService.logOut();
    }
  }

}
