<ngx-loading-bar color="#f47f38" height="5px"></ngx-loading-bar>
<app-header *ngIf="isAuthenticated"></app-header>
<app-sidebar *ngIf="isAuthenticated"></app-sidebar>
<section class="content" *ngIf="isAuthenticated && securityService && !securityService.admin">
    <div class="container-fluid" style="position: relative;top: 30px;z-index: 1;">
        <div class="block-header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <ul class="breadcrumb breadcrumb-style">
                        <li class="breadcrumb-item mr-4">
                            <h4 class="page-title">Choisir un inventaire</h4>
                        </li>
                        <li class="breadcrumb-item mr-4">
                            <select [(ngModel)]="idCurrentInv" class="select-trie"
                                style="width: 215px !important;" (change)="inventaireChange($event.target.value)">
                                <option *ngFor="let inventaire of inventaires" [value]="inventaire.id"> Inventaire <span
                                        *ngIf="inventaire.dateInv"> au {{inventaire.dateInv|date:'dd/MM/yyyy'}}</span>
                                </option>
                            </select>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<div *ngIf="securityService && securityService.admin" class="mb-5">&nbsp;</div>
<router-outlet *ngIf="isAuthenticated"></router-outlet>