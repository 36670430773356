import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from 'src/app/shared/service/security.service';

@Injectable({
  providedIn: 'root'
})
//ng generate guard core/guard/noAuth
export class SupervAdminGuard implements CanActivate {
  constructor(private router: Router,private securityService:SecurityService){ }
  canActivate(): Observable<boolean> | Promise<boolean> | boolean {//il va return soit un  Observable qui sera de type boolean soit ...un observable est un objet qui emmet des infos dans le temps
    const roles = localStorage.getItem('roles');
    const isAdmin = roles.search("ROLE_Admin")>=0;
    const isSuperviseur = roles.search("ROLE_Superviseur")>=0;
    const isGuest = roles.search("ROLE_Guest")>=0;
    const isSuperviseurGene = roles.search("ROLE_SuperViseurGene")>=0;
    const isSuperviseurAdjoint = roles.search("ROLE_SuperViseurAdjoint")>=0;


    if(!isAdmin && !isSuperviseur && !isGuest&& !isSuperviseurGene && !isSuperviseurAdjoint){
      this.router.navigateByUrl("/");
      return false;
    }
    return true;
}
}
