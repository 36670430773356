import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inventaireIsClosed'
})
export class InventaireIsClosedPipe implements PipeTransform {

  transform(value?: string, otherValidation?: boolean): boolean {
    return (localStorage.getItem('inventaireIsClosed') && (+localStorage.getItem('inventaireIsClosed')) === 1) || otherValidation;
  }
}
