import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { find } from 'rxjs/operators';
import { Entreprise } from 'src/app/data/schema/entreprise';
import { User } from 'src/app/data/schema/user';
import { EntrepriseService } from 'src/app/data/services/entreprise/entreprise.service';
import { SecurityService } from 'src/app/shared/service/security.service';
import { environment } from 'src/environments/environment';
import { AdminService } from '../../service/admin.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-personnels',
  templateUrl: './personnels.component.html',
  styleUrls: ['./personnels.component.sass']
})
export class PersonnelsComponent implements OnInit {
  dep = [
    'Direction financière',
    'Direction comptable',
    'Direction du patrimoine',
    'Autre (à préciser)'
  ];
  apiImageUrl = `${environment.apiUrl}/images/`;

  idCurrentEse: number;
  show: boolean;
  entreprises: Entreprise[];
  personnels: User[];
  allPersonnels: User[];
  filter: string;

  constructor(
    private entrepriseService: EntrepriseService,
    private adminService: AdminService,
    public securityService: SecurityService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.idCurrentEse = null;
    this.personnels = [];
    this.allPersonnels = [];
    this.entreprises = [];
    this.getEntreprises();
    this.getUsers();
  }

  getEntreprises() {
    this.adminService.getEntreprise().then(
      rep => {
        this.entreprises = rep
        if (rep && rep.length == 1) {
          this.idCurrentEse = rep[0].id;
        }
      },
      error => {
        this.securityService.showLoadingIndicatior.next(false)
        console.log(error)
      }
    )
  }

  getUsers() {
    let filters = 'status=OUT';
    this.adminService.getUsers(filters).then(
      (rep: any) => {
        this.allPersonnels = rep as Entreprise[];
        this.personnels = this.allPersonnels;
        this.show = true;
        this.securityService.showLoadingIndicatior.next(false)
      },
      error => {
        console.log(error)
        this.securityService.showLoadingIndicatior.next(false)
      }
    )
  }

  entiteChange(id: number, filter?: string) {
    this.idCurrentEse = id;
    this.filter = filter;
    this.personnels = [];

    if (!(+id) && !filter) {
      this.personnels = this.allPersonnels;
      return;
    }

    this.personnels = this.allPersonnels.filter((p: User) => {
      let _search = true;

      if ((+this.idCurrentEse)) {
        _search = _search && p.entreprises.find((e: any) => e.id == this.idCurrentEse);
      }

      if (this.filter) {
        filter = this.filter.toLocaleLowerCase();
        _search = _search && (p.nom.toLocaleLowerCase().indexOf(filter) !== -1 || p.departement.toLocaleLowerCase().indexOf(filter) !== -1 || p.poste.toLocaleLowerCase().indexOf(filter) !== -1); 
      }

      return _search;
    });
  }

  uploadPersonnels(evt: any) {
    if (!this.idCurrentEse) {
      this.showNotification('bg-danger', 'Veuillez choisir une entreprise', 'top', 'center');
      evt.target.value = '';
      return;
    }
    let fileList: FileList = evt.target.files;
    let fileUpload: File = fileList[0];
    const formData = new FormData();
    formData.append('file', fileUpload, fileUpload.name);
    formData.append('table', 'agents');
    formData.append('entreprise', `${this.idCurrentEse || ''}`);
    this.entrepriseService.importAgents(formData).subscribe((res: any) => {
      this.showNotification('bg-success', res, 'top', 'center');
      evt.target.value = '';
      setTimeout(() => {
        this.getUsers();
      }, 2000);
    }, error => evt.target.value = '');
  }

  removeUser(user: any) {
    this.confirmAlert("Voulez-vous supprimer cet utilisateur ?").then((result) => {
      if (result.isConfirmed) {
        this.adminService.removeOutUser(`idUser=${user.id}`).then((result: any) => {
          const index = this.allPersonnels.findIndex((p: any) => p.id == user.id);
          if (index !== -1) {
            this.allPersonnels.splice(index, 1);
            this.entiteChange(this.idCurrentEse, this.filter);
            this.showNotification('bg-success','Utilisateur supprimé avec succès','top','center');
          }
        })
      } else {
        Swal.DismissReason.cancel
      }
    })
  }

  removeAllUser() {
    this.confirmAlert("Voulez-vous supprimer la liste des utilisateurs de cette entreprise ?").then((result) => {
      if (result.isConfirmed) {
        this.adminService.removeOutUser(`entreprise=${this.idCurrentEse}`).then((result: any) => {
          this.showNotification('bg-success','Liste des utilisateurs supprimé avec succès','top','center');
          this.getUsers();
          setTimeout(() => {
            this.entiteChange(this.idCurrentEse, this.filter);
          }, 1000);
        })
      } else {
        Swal.DismissReason.cancel
      }
    })
  }

  confirmAlert(message?: string) {
    const confirm = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',

      },
      buttonsStyling: true
    })

    return confirm.fire({
      title: 'Suppression ?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui!',
      cancelButtonText: 'Non, annuler!',
      reverseButtons: true,
    })
  }

  showNotification(colorName, text, placementFrom, placementAlign, duree = 2000) {
    this._snackBar.open(text, '', {
      duration: duree,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: [colorName, 'color-white']
    });
  }
}
