import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventaireIsClosedPipe } from './pipe/inventaire-is-closed.pipe';
import { InInventairePipe } from './pipe/in-inventaire.pipe';
import { NotificationHighlightDirective } from './directives/notification-highlight.directive';



@NgModule({
  declarations: [InventaireIsClosedPipe, InInventairePipe, NotificationHighlightDirective],
  imports: [
    CommonModule
  ],
  exports: [
    InventaireIsClosedPipe,
    InInventairePipe,
    NotificationHighlightDirective
  ]
})
export class SharedModule { }
