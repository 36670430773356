<div>
    <!-- Left Sidebar -->
    <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
        <!-- Menu -->
        <div class="menu">
            <div class="logo-panel">
                <a routerLink="dashboard/main">
                    <img src="assets/logo-white.png" class="img-responsive" alt="" />
                </a>
            </div>
            <ul id="sidebarnav" class="list" style="padding-bottom: 150px;"
                [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
                [perfectScrollbar]>
                <li class="sidebar-user-panel">
                    <div class="user-panel" *ngIf="securityServ.user && securityServ.user.image">
                        <div class=" image">
                            <img *ngIf="!imagePP" [src]="sharedService.baseUrl +'/images/'+securityServ.user.image" class="img-pp img-circle user-img-circle pointer" alt="Image de profil" (click)="pp.click()" title="Changer sa photo de profil"/>
                            <img *ngIf="imagePP" [src]="imagePP" class="img-pp img-circle user-img-circle pointer" alt="Image de profil" (click)="pp.click()" title="Changer sa photo de profil"/>
                            <input type="file" id="image" accept="image/*" (change)="handleFileInputPP($event.target.files)" hidden #pp>
                        </div>
                    </div>
                    <div class="profile-usertitle"  *ngIf="securityServ.user && securityServ.user.nom">
                        <div class="sidebar-userpic-name"> {{securityServ.user?.nom}} </div>
                        <div class="profile-usertitle-job" [title]="securityServ.user?.poste">{{securityServ.fonction}}</div>
                    </div>
                </li>
                <!-- First level menu -->
                <li [ngClass]="{'active': showMenu === sidebarItem.title}" *ngFor="let sidebarItem of sidebarItems" class="menu-item"
                    [routerLinkActive]="sidebarItem.submenu.length != 0 ? '' : 'active'" [hidden]="!isGranted(sidebarItem)">
                    <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" class="ele-menu"
                        [ngClass]="[sidebarItem.class]" #eleMenu (click)="callMenuToggle(eleMenu, sidebarItem.title)">
                        <i [ngClass]="[sidebarItem.icon]"></i>
                        <span class="hide-menu">{{sidebarItem.title}}
                        </span>
                    </a>
                    <!-- Second level menu -->
                    <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
                        <li *ngFor="let sidebarSubItem of sidebarItem.submenu;let i=index"
                            [class.activeSub]="showSubMenu === sidebarSubItem.title"
                            [routerLinkActive]="sidebarSubItem.submenu.length > 0 ? '' : 'active'" 
                            [hidden]="!isGrantedSubM(sidebarItem,i)">
                            <a [routerLink]="sidebarSubItem.submenu.length > 0 ? null : [sidebarSubItem.path]"
                                (click)="callSubMenuToggle(sidebarSubItem.title)" [ngClass]="[sidebarSubItem.class]">
                                {{sidebarSubItem.title}} <span *ngIf="sidebarSubItem.title=='Ajuster FI' && securityServ.superviseurGene && countApprov>0" class="pl-2 pr-2" style="border-radius: 50%;border: 1px solid red;font-size: 11px;background-color: red;color: white;">{{countApprov}}</span>
                            </a>
                            <!-- Third level menu -->
                            <ul class="collapse" *ngIf="sidebarSubItem.submenu.length > 0"
                                [ngClass]="{'in' : showSubMenu === sidebarSubItem.title }">
                                <li *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                                    [ngClass]="[sidebarSubsubItem.class]">
                                    <a [routerLink]="[sidebarSubsubItem.path]">
                                        {{sidebarSubsubItem.title}}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <!-- First level menu -->
               
                <li [ngClass]="{'active': showMenu === 'Supports'}" routerLinkActive="active" class="menu-item" *ngIf="!securityServ.guest">
                    <a [routerLink]="['/supports']" class="ele-menu">
                        <i class="fas fa-life-ring"></i>
                        <span class="hide-menu">Support</span>
                    </a>
                </li>


                <input type="file" #fileMo hidden (change)="importMobileFile($event)" [(ngModel)]="inputMobilFile">
            </ul>
        </div>
        <!-- #Menu -->
        <div class="footer-sidebar" (click)="refreshPage()">
            <h4><em class="fas fa-sync mr-2"></em><span>Rafraîchir la page</span></h4>
        </div>
    </aside>
    <!-- #END# Left Sidebar -->
</div>
