import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/service/shared.service';
import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RealtimecallService {

  constructor(private SharedService: SharedService, private http: HttpClient) { 

  }


  geUnlockedRequest(){
   return interval(2*2000)
    .pipe(
        flatMap(() => this.SharedService.getElement(`/unlock_requests?status=false&inventaire.id=${localStorage.getItem('currentInv')}`))
    )
  }

  


  
}
