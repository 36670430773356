<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
    <div class="container-fluid">
        <div class="navbar-header">
            <a href="#" onClick="return false;" class="navbar-toggle collapsed" data-toggle="collapse"
                data-target="#navbar-collapse" aria-expanded="false"></a>
            <!-- mobile menu bars --> 
            <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
        </div>
        <div class="collapse navbar-collapse" id="navbar-collapse">
            <ul class="pull-left collapse-menu-icon">
                <li>
                    <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
                        <mat-icon>menu</mat-icon>
                    </button>
                </li>
                
            </ul>
            <ul class="pull-left collapse-menu-icon" *ngIf="getEntite() && !securityServ.admin">
                <li> 
                    <span class="p-1" style="font-size: x-large;"><strong>Entité : </strong>  {{getEntite()?.denomination}}</span>
                </li>
            </ul>
            
            <ul class="nav navbar-nav navbar-right">
                <!-- Full Screen Button -->
                <li class="fullscreen">
                    <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
                        <mat-icon>fullscreen</mat-icon>
                    </button>
                </li>
                
                <!-- #END# Full Screen Button -->
                <!-- #START# Notifications-->
                <li class="dropdown">
                    <button mat-icon-button data-toggle="dropdown" #openNotif class="nav-notification-icons">
                        <mat-icon style="color: blue;">notifications</mat-icon>
                        <span class="label-count bg-orange" *ngIf="news>0"></span>
                    </button>
                    <ul class="dropdown-menu pullDown">
                        <li class="header">NOTIFICATIONS <span *ngIf="news>0">( {{news}} )</span> </li>
                        <li class="body">
                            <ul class="menu" style="position: relative; max-width: 600px; max-height: 300px;"
                                [perfectScrollbar]>
                                <li>
                                    <ng-container *ngFor="let n of notifs">
                                        <a *ngIf="n.notification"  [routerLink]="n.notification.lien != '#' ? n.notification.lien : '/dashboard/main'" (click)="lireNotif(n.id)" [ngClass]="{'new-notif': n.status==0}">
                                            <span class="table-img msg-user">
                                                <img src="{{imgLink+n.notification.emetteur.image}}" alt="">
                                            </span>
                                            <span class="menu-info">
                                                <span class="menu-title">{{n.notification.emetteur.nom}}</span>
                                                <span class="menu-desc">
                                                    <i class="material-icons">access_time</i> {{n.notification.date |date:'dd/MM/yyyy à HH:mm'}}
                                                </span>
                                                <span class="menu-desc">{{n.notification.message}}</span>
                                            </span>
                                        </a>
                                    </ng-container>
                                    <a href="#" onClick="return false;" *ngIf="notifs.length==0">
                                        <span class="menu-info">
                                            <span class="menu-desc">Aucune notification</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li class="footer" [hidden]="!paginateN">
                            <a href="#" onClick="return false;" (click)="showAllNotif()">Voir toutes les Notifications</a>
                        </li>
                    </ul>
                </li>
                <!-- #END# Notifications-->

                <li>
                    <button  mat-icon-button (click)="showBasicDialog()" *ngIf="securityServ.superviseurAdjoint||securityServ.superviseur" class="nav-notification-icons" style="position: relative;">
                        <mat-icon id="lock" style="font-size: 32px;">lock</mat-icon>
                        <span style="display:block; position: absolute; top:0; color: red; font-size: 16px; background-color:white;  height: 22px; width:22px; border-radius: 50%; ">
                            <span style="display: block; width: 20px; margin-top: -.5em; font-weight: bold;" >{{unlockRequests.length}}</span>
                        </span>
                        
                    </button>
                </li>

                <li class="dropdown user_profile" >
                    <a href="#" onClick="return false;" data-toggle="dropdown" role="button" class="pt-0">
                        <img *ngIf="securityServ.user && securityServ.user.image" [src]="sharedService.baseUrl +'/images/'+securityServ.user.image" class="rounded-circle" width="32" height="32" alt="User">
                        <img src="assets/images/user/user1.jpg" class="rounded-circle" width="32" height="32" alt="User"  *ngIf="!(securityServ.user && securityServ.user.image)">
                    </a>
                    <ul class="dropdown-menu pullDown">
                        <li class="body">
                            <ul class="user_dw_menu">
                                <li [hidden]="securityServ.user?.entreprises?.length==1||securityServ.admin">
                                    <a href="#" onClick="return false;"  data-toggle="modal" data-target="#eseModal" data-backdrop="static" data-keyboard="false" (click)="initForm3()" #openEseModal>
                                        <mat-icon aria-hidden="false" class="mr-2">business</mat-icon>Changer d'entité
                                    </a>
                                </li>
                                <li *ngIf="securityServ.superviseurAdjoint||securityServ.superviseur" [hidden]="'' | inventaireIsClosed">
                                    <a href="#" onClick="return false;" data-toggle="modal" data-target="#unLockModal" data-backdrop="static" data-keyboard="false" (click)="initForm3()" #openEseModal>
                                        <mat-icon aria-hidden="false" class="mr-2">lock_open</mat-icon>Code déverrouillage
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick="return false;"  data-toggle="modal" data-target="#infoModal" data-backdrop="static" data-keyboard="false" (click)="updateInfos()">
                                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Infos personnelles
                                    </a>
                                </li>
                                <li>
                                    <a href="#" onClick="return false;"  data-toggle="modal" data-target="#passwordModal" data-backdrop="static" data-keyboard="false" (click)="updatePwd()" #openPasswordModal>
                                        <mat-icon aria-hidden="false" class="mr-2">vpn_key</mat-icon>Mot de passe
                                    </a>
                                </li>
                                <li [hidden]="!securityServ.admin">
                                    <a href="#" onClick="return false;"  data-toggle="modal" data-target="#keyModal" data-backdrop="static" data-keyboard="false" (click)="activNewKey()" #openKeyModal>
                                        <mat-icon aria-hidden="false" class="mr-2">domain</mat-icon>Licence
                                    </a>
                                </li>
                                <li>
                                    <a [routerLink]="['/supports/new']">
                                        <em class="fas fa-life-ring mr-2"></em>Support
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/login" (click)="logOut()">
                                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Déconnexion
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- Modal Window Password-->
<div class="modal fade" id="passwordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false">
    <div class="modal-dialog mt-5" role="document">
        <form class="modal-content"  [formGroup]="editForm" (ngSubmit)="onSavePwd(editForm)"  #formDirective="ngForm">
            <div class="modal-header">
                <div class="editRowModal">
                    <div class="modal-header clearfix">
                        <div class="modal-about">
                            <div class="font-weight-bold p-t-10 font-17">Modifier mot de passe</div>
                        </div>
                    </div>
                </div>
                <button type="button" #closePasswordModal
                    class="btn p-t-10 modal-close-button" data-dismiss="modal"
                    aria-label="Close">
                    <i class="material-icons">clear</i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width"
                                appearance="outline">
                                <mat-label>Ancien mot de passe</mat-label>
                                <input matInput type="password" formControlName="ancien" *ngIf="!showPwd" >
                                <input matInput type="text" formControlName="ancien" *ngIf="showPwd" >
                                <mat-icon matSuffix  *ngIf="!showPwd" (click)="showPwd=!showPwd" class="pointer">visibility</mat-icon>
                                <mat-icon matSuffix  *ngIf="showPwd" (click)="showPwd=!showPwd" class="pointer">visibility_off</mat-icon>
                                <mat-error *ngIf="editForm.get('ancien').hasError('required')">L' ancien mot de passse est requise</mat-error>
                                <mat-error *ngIf="editForm.get('ancien').hasError('ancienMdp')">Le mot de passe est invalide</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width"
                                appearance="outline">
                                <mat-label>Nouveau mot de passe</mat-label>
                                <input matInput type="password" formControlName="password" *ngIf="!showPwd2">
                                <input matInput type="text" formControlName="password" *ngIf="showPwd2" >
                                <mat-icon matSuffix  *ngIf="!showPwd2" (click)="showPwd2=!showPwd2" class="pointer">visibility</mat-icon>
                                <mat-icon matSuffix  *ngIf="showPwd2" (click)="showPwd2=!showPwd2" class="pointer">visibility_off</mat-icon>
                                <mat-error *ngIf="editForm.get('password').hasError('required')">Le nouveau mot de passse est requise</mat-error>
                                <mat-error *ngIf="editForm.get('password').hasError('minlength')">Minimum 6 caractères</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width"
                                appearance="outline">
                                <mat-label>Confirmer le mot de passe</mat-label>
                                <input matInput type="password" formControlName="confPassword" *ngIf="!showPwd3" >
                                <input matInput type="text" formControlName="confPassword" *ngIf="showPwd3" >
                                <mat-icon matSuffix  *ngIf="!showPwd3" (click)="showPwd3=!showPwd3" class="pointer">visibility</mat-icon>
                                <mat-icon matSuffix  *ngIf="showPwd3" (click)="showPwd3=!showPwd3" class="pointer" class="pointer">visibility_off</mat-icon>
                                <mat-error *ngIf="editForm.get('confPassword').hasError('required')">La confirmation du mot de passse est requise</mat-error>
                                <mat-error *ngIf="editForm.get('confPassword').hasError('minlength')">Minimum 6 caractères</mat-error>
                                <mat-error *ngIf="errorConfPwd">Les mots de passe ne concordent pas</mat-error>
                                <mat-error *ngIf="editForm.get('confPassword').hasError('mustMatch')">Les mots de passe ne concordent pas</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="modal-footer pr-0">
                        <div class="button-demo">
                            <button type="submit" class="btn btn-outline-success btn-border-radius" [disabled]="editForm.invalid">Enregistrer</button>
                            <button type="button" class="btn btn-outline-danger btn-border-radius mr-0" data-dismiss="modal" [disabled]="!securityServ.securePwd">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Modal Window Password-->

<!-- Modal Window Info-->
<div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false" *ngIf="updateInfo">
    <div class="modal-dialog" role="document">
        <form class="modal-content"  [formGroup]="InfoForm" (ngSubmit)="onSaveInfo(InfoForm)">
            <div class="modal-header">
                <div class="editRowModal">
                    <div class="modal-header clearfix">
                        <div class="modal-about">
                            <div class="font-weight-bold p-t-10 font-17">Modifier mes informations</div>
                        </div>
                    </div>
                </div>
                <button type="button" #closeInfoModal
                    class="btn p-t-10 modal-close-button" data-dismiss="modal"
                    aria-label="Close">
                    <i class="material-icons">clear</i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width"
                                appearance="outline">
                                <mat-label>Prénom et nom</mat-label>
                                <input matInput formControlName="nom" >
                                <mat-icon matSuffix >face</mat-icon>
                                <mat-error *ngIf="InfoForm.get('nom').hasError('required')">
                                    Ce champ est requise
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <mat-form-field class="example-full-width"
                                appearance="outline">
                                <mat-label>Poste</mat-label>
                                <input matInput formControlName="poste" >
                                <mat-icon matSuffix >card_travel</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="modal-footer pr-0">
                        <div class="button-demo">
                            <button type="submit" class="btn btn-outline-success btn-border-radius" [disabled]="InfoForm.invalid">Enregistrer</button>
                            <button type="button" class="btn btn-outline-danger btn-border-radius mr-0" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Modal Window Info-->

<!-- Modal entreprise-->
<div class="modal fade" id="eseModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false" *ngIf="securityServ.user?.entreprises?.length>1" [hidden]="!securityServ.securePwd">
    <div class="modal-dialog mt-5" role="document">
        <form class="modal-content"  [formGroup]="eseForm" (ngSubmit)="onSaveEse(eseForm)">
            <div class="modal-header">
                <div class="editRowModal">
                    <div class="modal-header clearfix">
                        <div class="modal-about">
                            <div class="font-weight-bold p-t-10 font-17">Choisissez votre entité</div>
                        </div>
                    </div>
                </div>
                <button type="button" #closeEseModal
                    class="btn p-t-10 modal-close-button" data-dismiss="modal"
                    aria-label="Close" hidden>
                    <i class="material-icons">clear</i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
                            <select name="" id="" formControlName="entreprise" class="form-control">
                                <option value=""></option>
                                <option *ngFor="let e of securityServ.user?.entreprises" [value]="e.id">{{e.denomination}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer pr-0">
                        <div class="button-demo">
                            <button type="submit" class="btn btn-outline-success btn-border-radius" [disabled]="eseForm.invalid">Valider</button>
                            <!-- <button type="button" class="btn btn-outline-danger btn-border-radius mr-0" data-dismiss="modal" [disabled]="eseForm.invalid||!idEse">Annuler</button> -->
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Modal entreprise-->

<!-- Modal key-->
<div class="modal fade" id="keyModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false" *ngIf="securityServ.activCle||activCle">
    <div class="modal-dialog mt-5" role="document">
        <form class="modal-content" (ngSubmit)="onSubmitCle()">
            <div class="modal-header">
                <div class="editRowModal">
                    <div class="modal-header clearfix">
                        <div class="modal-about">
                            <div class="font-weight-bold p-t-10 font-17">Activation licence</div>
                        </div>
                    </div>
                </div>
                <button type="button" #closeKeyModal
                    class="btn p-t-10 modal-close-button" data-dismiss="modal"
                    aria-label="Close" hidden>
                    <i class="material-icons">clear</i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-0">
                        <mat-form-field class="example-full-width"
                            appearance="outline">
                            <mat-label>Clé d'activation</mat-label>
                            <input matInput [(ngModel)]="cle" [ngModelOptions]="{standalone: true}">
                            <mat-icon matSuffix >key</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="modal-footer pr-0 pt-0">
                        <div class="button-demo">
                            <button type="submit" class="btn btn-outline-success btn-border-radius" [disabled]="!cle">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Modal key-->

<!-- Modal deverrouillage loc -->
<div class="modal fade" id="unLockModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-keyboard="false">
    <div class="modal-dialog mt-5" role="document">
        <form class="modal-content" (ngSubmit)="getCode()">
            <div class="modal-header">
                <div class="editRowModal">
                    <div class="modal-header clearfix">
                        <div class="modal-about">
                            <div class="font-weight-bold p-t-10 font-17">Déverrouillage localité</div>
                        </div>
                    </div>
                </div>
                <button type="button"  (click)="showCode=false" #closeUnLockModal
                    class="btn p-t-10 modal-close-button" data-dismiss="modal"
                    aria-label="Close">
                    <i class="material-icons">clear</i>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="!showCode">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-0">
                        <select name="" id="" [(ngModel)]="inv" [ngModelOptions]="{standalone: true}" class="form-control" (change)="invChange($event.target.value)">
                            <option value=""></option>
                            <ng-container *ngFor="let inventaire of inventaires">
                                <option *ngIf="inventaire.status !=='close' && inventaire.id == idCurrentInv" [value]="inventaire.id">Inventaire au {{inventaire.dateInv|date:'dd/MM/yyyy'}}</option>
                            </ng-container>                            
                        </select>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-0 mt-4">
                        <select name="" id="" [(ngModel)]="loc" [ngModelOptions]="{standalone: true}" class="form-control">
                            <option value=""></option>
                            <option *ngFor="let localite of localites" [value]="localite.id">{{localite.arborescence}}</option>
                        </select>
                    </div>
                    <div class="modal-footer pr-0 pt-0">
                        <div class="button-demo">
                            <button type="submit" class="btn btn-outline-success btn-border-radius" [disabled]="!loc || !inv">Valider</button>
                            <button type="button" class="btn btn-outline-danger btn-border-radius mr-0" data-dismiss="modal">Annuler</button>
                        </div>
                    </div>
                </div>
                <div class="" *ngIf="showCode">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2 p-0">
                        <mat-form-field class="example-full-width"
                            appearance="outline">
                            <mat-label>Code déverouillage</mat-label>
                            <input matInput [(ngModel)]="unLockCode" [ngModelOptions]="{standalone: true}" disabled>
                            <mat-icon matSuffix >key</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="modal-footer pr-0 pt-0">
                        <div class="button-demo">
                            <button type="button" class="btn btn-outline-danger btn-border-radius mr-0" (click)="showCode=false">Retour</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        
    </div>
</div>
<!-- Modal deverrouillage loc -->

<p-dialog header="Demande de dévérouillage" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000" position="top">
    <div *ngFor="let unlock of unlockRequests" class="row px-3 pt-3 pb-2 text-white pt-2"  style="background-color: #D76104; font-size: 20px; font-weight: 600; margin-bottom: 1em;">
        <div class="col-sm-4 d-flex">
            <span style=" display:block; width:30px; height:30px; border-radius: 50%; background-color: rgb(223, 221, 221)"></span>
            <span class="ml-4">{{unlock.user.nom}}</span>
        </div>
        <div class="col-sm-6 d-flex">
            <mat-icon>home</mat-icon>
           <p class="ml-2 mt-1" style="font-size: 12px">{{unlock.localite.arborescence}}</p>
        </div>
        <div class="col-sm-2">
            <button class="btn btn-primary" [disabled]="idUnlockRequests.indexOf(unlock.id) != -1" (click)="sendCode(unlock)" >
                <span class="spinner-border spinner-border-sm mb-1" *ngIf="idUnlockRequests.indexOf(unlock.id) != -1" role="status" aria-hidden="true"></span>
                Envoyer
            </button>
        </div>
    </div>
    
</p-dialog>