import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntrepriseComponent } from './components/entreprise/entreprise.component';
import { AdminGuard } from 'src/app/core/guard/admin.guard';
import { SupervAdminGuard } from 'src/app/core/guard/superv-admin.guard';
import { PersonnelsComponent } from './components/personnels/personnels.component';
import { RoleGuard } from 'src/app/core/guard/role.guard';
import { Roles } from 'src/app/data/constants/roles';
const routes: Routes = [
  {
    path: 'entreprise',
    canActivate:[RoleGuard],
    data: { roles: [Roles.ROLE_ADMIN]},
    component: EntrepriseComponent
  },
  {
    path: 'personnels',
    canActivate:[RoleGuard],
    data: { roles: [Roles.ROLE_ADMIN]},
    component: PersonnelsComponent
  }
]; 

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }
