import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/service/shared.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ImmobilisationService {
  approvChange: Subject<boolean> = new Subject<boolean>();
  constructor(private sharedService:SharedService) { }

  getAllImmosByEntreprise(entreprise: number, inventaire: number, page?: number, count = 10, filters = '') {
    if (filters && filters.charAt(0) === '&') {
      filters = filters.substring(1);
    }
    return this.sharedService.getElement(`/immobilisations?entreprise.id=${entreprise}&inventaire.id=${inventaire}&pagination=true&page=${page}&count=${count}&${filters}`);
  }

  getAllInventaire() {
    return this.sharedService.getElement("/inventaires");
  }

  postImmobilisation(data : any) {  
    if(data.id && data.id!=0){
      return this.sharedService.putElement(data,"/immobilisations/"+data.id)
    }
    delete data.id;
    return this.sharedService.postElement(data,"/immobilisations")
  }

  approveAjustement(id,value) {
    return this.sharedService.getElement(`/approuve/ajustement/${id}/${value}`);
  }

  patchImmo(id: number, data: any) {
    return this.sharedService.putElement({id: id, fields: data}, `/immobilisations/patch/${id}`);
  }

  getImmobilisationByInventaire(id,params='') {
    return this.sharedService.getElement(`/immobilisations?inventaire.id=${id}&${params}`);
  }

  getCountImmoToApprovByEse(id) {
    return this.sharedService.getElement(`/approuve/waitting/${id}`);
  }

  deleteImmoByInventaire(id) {
    return this.sharedService.getElement(`/immobilisations/delete/${id}/inventaire`);
  }

  deleteImmoByEntreprise(idEntreprise: number, idInventaire: number) {
    return this.sharedService.getElement(`/immobilisations/delete/${idEntreprise}/${idInventaire}/entreprise`);
  }

  getFilterdFields() {
    return [
      { name: 'code', value: 'Filtrer par code', icon: 'link' },
      { name: 'libelle', value: 'Filtrer par libellé', icon: 'short_text' },
      { name: 'localite.arborescence', value: 'Filtrer par localité', icon: 'location_on' },
      { name: 'lecteur.nom', value: 'Filtrer par lecteur', icon: 'person_outline' },
      { name: 'dateLecture', value: 'Filtrer par date de lecture', icon: 'date_range' }
    ];
  }

  getImmoImage(name: string) {
    return `${environment.apiUrl}/images/${name}`;
  }
}
 